import DropDownMenu_PointerMenuButtonSkinComponent from '../components/DropDownMenu/viewer/skinComps/PointerMenuButton/PointerMenuButtonSkin.skin';
import DropDownMenu_PointerMenuButtonSkinController from '../components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_PointerMenuButtonSkin = {
  component: DropDownMenu_PointerMenuButtonSkinComponent,
  controller: DropDownMenu_PointerMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_PointerMenuButtonSkin']: DropDownMenu_PointerMenuButtonSkin
};

